import { Utils } from '@/Utils';

$(document).on('click', '.requestAccessBtn', function () {
  window.$.ajax({
    url: '/request-access',
    method: 'POST',
    success: (response) => {
      window.toast(response.message, 'success');
    },
    error: (error) => {
      let message = Utils.getStatusMessage(500, false);

      if (typeof error?.responseJSON?.message !== 'undefined') {
        message = error.responseJSON.message;
      }

      window.toast(message, 'error');
    }
  });
});
